<template>
	<div>
		<div class="dropdown selectable">
			<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				{{initialText}}
			</button>
			<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<button
					type="button"
					v-for="(item, index) in dropdownList"
					:key="index"
					:active="value && item.key == value"
					class="dropdown-item"
					href="#"
					@click="item.action( item.key )"
				>
					{{ item.text }}
				</button>
			</div>
		</div>
	</div>

</template>

<script>

export default {
	name: 'Dropdown',
	props:{
		dropdownButtonText: {
			type: String,
			default: ''
		},
		dropdownList: {
			type: Array,
			default: () => []
		},
		emptyLabel: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: ''
		}
	},
	computed: {
		initialText(){

			if( this.dropdownList && this.value ) {
				for( let i = 0; i < this.dropdownList.length; i ++ ) {
					if( this.dropdownList[i].key == this.value ) return this.dropdownList[i].text; 
				}
			}

			return this.dropdownButtonText
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .selectable > button {
	padding: 10px 15px;
	background-color: #fff !important;
	color: #767676;
	border-color: #BDC3C7;
	transition: all .2s ease;
	min-width: 200px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
	font-weight: 500;
	font-size: 0.938rem;
	letter-spacing: 0.02em;
	border-radius: 5px;

	&:active {
		background-color: #fff !important;
		border-color: #BDC3C7 !important;
		color: #2c3e50 !important;
	}
	&:active:focus,
	&:focus-visible,
	&:focus {
		outline: none;
		box-shadow: none;
	}

	&::after
	{
		font-size: 1rem;
		transition: all 300ms ease-out;
	}

	&.dropdown-toggle {
		border-color: #F0F0F0!important;
	}
}

::v-deep .selectable > button[aria-expanded="true"]
{
	color: #2c3e50 !important;
	border-color: #14AAF5;

	&:hover
	{
		border-color: #14AAF5 !important;
	}

	&::after
	{
		transform: rotate(180deg);
	}
}

::v-deep .selectable > ul {
    right: 0;
	top: -2px;
	padding: 5px 0;
	border: none;
	box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
	padding: 0px;
	width: 100%;
	border-radius: 0px 0.25rem !important;
	max-height: 400px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: #BABABA;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e7e7e7;
	}

	& > li a.dropdown-item
	{
		transition: all 300ms ease-out;
		border-bottom: 1px solid #eef1f2;

		&:hover
		{
			text-decoration: none;
			background-color: transparent !important;
		}

		&.active {
			background-color: transparent;
		}
	}

	& > li {
		padding-left: 10px;
		padding-right: 10px;
		transition: all 300ms ease-out;

		&:hover
		{
			background-color: #e9ecef;
		}

		& > a {
			padding: 0;
			word-break: break-word;
			width: 100%;
			white-space: normal;

			text-decoration: none;
			
			&:active {
				background: #fff;
			}
		}
	}

	.list-item {
		p {
			padding: 0;
			font-size: 0.938rem;
			font-weight: 400;
			line-height: normal;

			a {
				color: #212529;
				display: block;
				padding: 10px 5px;
				text-decoration: none;
			}
		}
	}
}
::v-deep .selectable {
	.dropdown-toggle {
		text-transform: capitalize;
		font-weight: 400;
		border-color: #cecece!important;
		background-color: #fff!important;
		padding: 0.75rem 1rem;
	}
	button.btn-secondary:focus, 
	a.btn-secondary:focus,
	.btn-secondary:not(:disabled):not(.disabled):active, 
	.btn-secondary:not(:disabled):not(.disabled).active, 
	&.show > .btn-secondary.dropdown-toggle {
		background-color: #fff!important;
		color: #212529;
		border-color: #14AAF5!important;
	}
	ul {
		transform: translate3d(0px, 34px, 0px) !important;

		@media (min-width: 992px) {
			transform: translate3d(0px, 38px, 0px) !important;
		}

		@media (min-width: 1200px) {
			transform: translate3d(0px, 46px, 0px) !important;
		}
	}

	.selectable-empty {
		padding: 10px 20px;
		font-size: 1rem;
	}
}
</style>